.btn, .fave-load-more a, .post-password-form input[type='submit'] {
  font-family: "Roboto", sans-serif;
  padding: 10px 15px;
  dsiplay: inline-block;
  margin-bottom: 0;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  line-height: 1.42857;
  margin: 0;
  font-weight: 500;
  text-transform: inherit;
  text-align: center;
  /* mini phones and down */
}

@media (max-width: 767px) {
  .btn, .fave-load-more a, .post-password-form input[type='submit'] {
    padding: 6px 15px;
    font-size: 12px;
  }
}

.btn-long {
  padding-left: 55px;
  padding-right: 55px;
}

.btn-md {
  padding: 8px 15px;
}

/*.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}*/
/*.btn:hover,
.btn:focus,
.btn.focus {
  color: #fff;
  text-decoration: none;
}*/

.btn:active, .fave-load-more a:active, .post-password-form input[type='submit']:active, .btn.active, .fave-load-more a.active, .post-password-form input.active[type='submit'] {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled, .fave-load-more a.disabled, .post-password-form input.disabled[type='submit'], .btn[disabled], .fave-load-more a[disabled], .post-password-form input[disabled][type='submit'] {
  cursor: not-allowed;
  filter: alpha(opacity = 65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

fieldset[disabled] {
  .btn, .fave-load-more a {
    cursor: not-allowed;
    filter: alpha(opacity = 65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
  }
}

.fave-load-more fieldset[disabled] a, fieldset[disabled] .post-password-form input[type='submit'], .post-password-form fieldset[disabled] input[type='submit'] {
  cursor: not-allowed;
  filter: alpha(opacity = 65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

a.btn.disabled, .fave-load-more a.disabled {
  pointer-events: none;
}

fieldset[disabled] {
  a.btn, .fave-load-more a {
    pointer-events: none;
  }
}

.fave-load-more fieldset[disabled] a {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  &:hover, &:active, &.active {
    color: #333;
    background-color: #fff;
    border-color: #adadad;
  }
}

/*.btn-default:focus,
.btn-default.focus {
  color: inherit;
  background-color: #e6e6e6;
  border-color: inherit;
}*/

.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #adadad;
}

.btn-default {
  &:active:hover, &.active:hover {
    color: #333;
    /*background-color: #d4d4d4;
    border-color: #8c8c8c;*/
  }
}

.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  /*background-color: #d4d4d4;
  border-color: #8c8c8c;*/
}

.btn-default {
  &:active:focus, &.active:focus {
    color: #333;
    /*background-color: #d4d4d4;
    border-color: #8c8c8c;*/
  }
}

.open > .dropdown-toggle.btn-default:focus {
  color: #333;
  /*background-color: #d4d4d4;
  border-color: #8c8c8c;*/
}

.btn-default {
  &:active.focus, &.active.focus {
    color: #333;
    /*background-color: #d4d4d4;
    border-color: #8c8c8c;*/
  }
}

.open > .dropdown-toggle.btn-default.focus {
  color: #333;
  /*background-color: #d4d4d4;
  border-color: #8c8c8c;*/
}

.btn-default {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default {
  &.disabled, &[disabled] {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  &.disabled:hover, &[disabled]:hover {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  &.disabled:focus, &[disabled]:focus {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default:focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  &.disabled.focus, &[disabled].focus {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  &.disabled:active, &[disabled]:active {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default:active {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  &.disabled.active, &[disabled].active {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #00aeef;
  border-color: #2e6da4;
  &:focus, &.focus {
    color: #fff;
    background-color: #4cc6f4;
    border-color: #122b40;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #4cc6f4;
    border-color: #204d74;
  }
}

.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #4cc6f4;
  border-color: #204d74;
}

.btn-primary {
  &:active:hover, &.active:hover {
    color: #fff;
    /*background-color: #0497ce;
    border-color: #122b40;*/
  }
}

.open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  /*background-color: #0497ce;
  border-color: #122b40;*/
}

.btn-primary {
  &:active:focus, &.active:focus {
    color: #fff;
    /*background-color: #0497ce;
    border-color: #122b40;*/
  }
}

.open > .dropdown-toggle.btn-primary:focus {
  color: #fff;
  /*background-color: #0497ce;
  border-color: #122b40;*/
}

.btn-primary {
  &:active.focus, &.active.focus {
    color: #fff;
    /*background-color: #0497ce;
    border-color: #122b40;*/
  }
}

.open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  /*background-color: #0497ce;
  border-color: #122b40;*/
}

.btn-primary {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary {
  &.disabled, &[disabled] {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .btn-primary {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary {
  &.disabled:hover, &[disabled]:hover {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary {
  &.disabled:focus, &[disabled]:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .btn-primary:focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary {
  &.disabled.focus, &[disabled].focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .btn-primary.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary {
  &.disabled:active, &[disabled]:active {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .btn-primary:active {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary {
  &.disabled.active, &[disabled].active {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .btn-primary.active {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}

.btn-secondary {
  color: #fff;
  background-color: $color-red; //#ff6e00;
  border-color: $color-red;
  &:focus, &.focus, &:hover, &:active, &.active {
    color: #fff;
    background-color: darken($color-red, 10%); //#e66300;
    border-color: darken($color-red, 10%);
  }
}

.open > .dropdown-toggle.btn-secondary {
  color: #fff;
  background-color: #e66300;
  border-color: #e66300;
}

.btn-secondary {
  &:active:hover, &.active:hover {
    color: #fff;
    /*background-color: #204d74;
    border-color: #122b40;*/
  }
}

.open > .dropdown-toggle.btn-secondary:hover {
  color: #fff;
  /*background-color: #204d74;
  border-color: #122b40;*/
}

.btn-secondary {
  &:active:focus, &.active:focus {
    color: #fff;
    /*background-color: #204d74;
    border-color: #122b40;*/
  }
}

.open > .dropdown-toggle.btn-secondary:focus {
  color: #fff;
  /*background-color: #204d74;
  border-color: #122b40;*/
}

.btn-secondary {
  &:active.focus, &.active.focus {
    color: #fff;
    /*background-color: #204d74;
    border-color: #122b40;*/
  }
}

.open > .dropdown-toggle.btn-secondary.focus {
  color: #fff;
  /*background-color: #204d74;
  border-color: #122b40;*/
}

.btn-secondary {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-secondary {
  background-image: none;
}

.btn-secondary {
  &.disabled, &[disabled] {
    /*background-color: #337ab7;
    border-color: #2e6da4;*/
  }
}

fieldset[disabled] .btn-secondary {
  /*background-color: #337ab7;
  border-color: #2e6da4;*/
}

.btn-secondary {
  &.disabled:hover, &[disabled]:hover {
    /*background-color: #337ab7;
    border-color: #2e6da4;*/
  }
}

fieldset[disabled] .btn-secondary:hover {
  /*background-color: #337ab7;
  border-color: #2e6da4;*/
}

.btn-secondary {
  &.disabled:focus, &[disabled]:focus {
    /*background-color: #337ab7;
    border-color: #2e6da4;*/
  }
}

fieldset[disabled] .btn-secondary:focus {
  /*background-color: #337ab7;
  border-color: #2e6da4;*/
}

.btn-secondary {
  &.disabled.focus, &[disabled].focus {
    /*background-color: #337ab7;
    border-color: #2e6da4;*/
  }
}

fieldset[disabled] .btn-secondary.focus {
  /*background-color: #337ab7;
  border-color: #2e6da4;*/
}

.btn-secondary {
  &.disabled:active, &[disabled]:active {
    /*background-color: #337ab7;
    border-color: #2e6da4;*/
  }
}

fieldset[disabled] .btn-secondary:active {
  /*background-color: #337ab7;
  border-color: #2e6da4;*/
}

.btn-secondary {
  &.disabled.active, &[disabled].active {
    /*background-color: #337ab7;
    border-color: #2e6da4;*/
  }
}

fieldset[disabled] .btn-secondary.active {
  /*background-color: #337ab7;
  border-color: #2e6da4;*/
}

.btn-secondary .badge {
  color: #337ab7;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  &:focus, &.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
  }
}

.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }
}

.open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }
}

.open > .dropdown-toggle.btn-success:focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }
}

.open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success {
  &.disabled, &[disabled] {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success {
  &.disabled:hover, &[disabled]:hover {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success {
  &.disabled:focus, &[disabled]:focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success:focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success {
  &.disabled.focus, &[disabled].focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success {
  &.disabled:active, &[disabled]:active {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success:active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success {
  &.disabled.active, &[disabled].active {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
  &:focus, &.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc;
  }
}

.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
  }
}

.open > .dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
  }
}

.open > .dropdown-toggle.btn-info:focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
  }
}

.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info {
  &.disabled, &[disabled] {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .btn-info {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info {
  &.disabled:hover, &[disabled]:hover {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info {
  &.disabled:focus, &[disabled]:focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .btn-info:focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info {
  &.disabled.focus, &[disabled].focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info {
  &.disabled:active, &[disabled]:active {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .btn-info:active {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info {
  &.disabled.active, &[disabled].active {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
  &:focus, &.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
  }
}

.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d;
  }
}

.open > .dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d;
  }
}

.open > .dropdown-toggle.btn-warning:focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d;
  }
}

.open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning {
  &.disabled, &[disabled] {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .btn-warning {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning {
  &.disabled:hover, &[disabled]:hover {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning {
  &.disabled:focus, &[disabled]:focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .btn-warning:focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning {
  &.disabled.focus, &[disabled].focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning {
  &.disabled:active, &[disabled]:active {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .btn-warning:active {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning {
  &.disabled.active, &[disabled].active {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  &:focus, &.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
  }
}

.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19;
  }
}

.open > .dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19;
  }
}

.open > .dropdown-toggle.btn-danger:focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19;
  }
}

.open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger {
  &.disabled, &[disabled] {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .btn-danger {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger {
  &.disabled:hover, &[disabled]:hover {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger {
  &.disabled:focus, &[disabled]:focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .btn-danger:focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger {
  &.disabled.focus, &[disabled].focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger {
  &.disabled:active, &[disabled]:active {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .btn-danger:active {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger {
  &.disabled.active, &[disabled].active {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .btn-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  font-weight: normal;
  color: #337ab7;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  &:active, &.active, &[disabled] {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link {
  border-color: transparent;
  &:hover, &:focus, &:active {
    border-color: transparent;
  }
  &:hover, &:focus {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled]:hover {
    color: #777;
    text-decoration: none;
  }
}

fieldset[disabled] .btn-link:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:focus {
  color: #777;
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn, .fave-load-more .btn-group-lg > a, .post-password-form .btn-group-lg > input[type='submit'] {
  padding: 12px 18px;
  font-size: 16px;
  line-height: 1.3333333;
  border-radius: 4px;
}

.btn-sm, .btn-group-sm > .btn, .fave-load-more .btn-group-sm > a, .post-password-form .btn-group-sm > input[type='submit'] {
  padding: 5px 10px !important;
  font-size: 12px;
  line-height: 1.42857;
  margin: 0;
  font-weight: 400;
  text-transform: inherit;
  text-align: center;
  border-radius: 3px;
}

.btn-xs, .btn-group-xs > .btn, .fave-load-more .btn-group-xs > a, .post-password-form .btn-group-xs > input[type='submit'] {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
  + .btn-block {
    margin-top: 5px;
  }
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
