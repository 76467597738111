#property-location {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    li {
      float: left;
      padding-left: 10px;
      padding-right: 10px;
      list-style: none;
      text-align: center;
      color: #333333;
      font-size: 22px;
      font-weight: 700;
      border-right: 1px dotted #ccc;
      margin-bottom: 15px;
      flex-grow: 1;
      flex-basis: 145px;
    }
  }
}

  #property-detail {
    background: #fff;
    z-index: 99;
    position: relative;
    margin: 20px 0;
    .property-list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      li {
        width: 14.2%;
        float: left;
        padding-left: 10px;
        padding-right: 10px;
        list-style: none;
        text-align: center;
        color: #333333;
        font-size: 22px;
        font-weight: 700;
        border-right: 1px dotted #ccc;
        padding-bottom: 15px;
        flex-grow: 1;
        flex-basis: 145px;
        h5 {
          color: #737376;
          font-weight: 700;
          font-size: 12px;
          padding-bottom: 0;
          margin-bottom: 5px;
        }
        &.icon {
          img {
            height: 80px;
            padding: 15px 0;
            fill: $color-red;
            vertical-align: bottom;
          }
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }