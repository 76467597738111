/*START RANGE SLIDER STYLE*/
.range-advanced-main {
  margin-bottom: 20px;
}

.range-text p {
  color: #FFF;
  line-height: 16px;
  font-weight: 300;
  margin-bottom: 13px;
}

.range-title {
  color: #959595;
  font-weight: 500;
}

.min-price-range,
.max-price-range {
  color: #00aeef;
  font-weight: 500;
}

.range-wrap {
  padding: 0 5px;
  .ui-widget.ui-widget-content {
    position: relative;
  }
}

.radius-range-wrap {
  padding: 7px 5px 0 5px;
}

.radius-text-wrap {
  font-size: 14px;
  font-weight: 300;
  color: #959595;
}

.range-input {
  display: none;
}