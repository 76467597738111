// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower

//@import "../../bower_components/bootstrap-select-sass/sass/bootstrap-select-sass.scss";

$color-blue: #1c344c; //#004274;
$color-blue-2: #004274;
$color-blue-light: #00aeef;
$color-red: #e92c48;

@import "components/typography";
@import "components/btn";
@import "components/form";
@import "layout/footer";
@import "layout/unit-details";
@import "base";

/*
@import "bootstrap";
@import "bootstrap-select";
@import "bootstrap-theme";
@import "component";
@import "documentation";
@import "font-awesome";
@import "jquery-ui";
@import "options";
@import "owl.carousel";
@import "owl.theme";
@import "prettyPhoto";
@import "slick";
@import "slick-theme";
@import "styles";
@import "vegas";*/
