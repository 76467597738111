/*START HOMEPAGE FOOTER STYLE*/
#footer-section {
    width: 100%;
  }
  
  .footer {
    background-color: $color-blue;
    padding: 100px 0 70px;
    /* mini phones and down */
  }
  @media (max-width: 767px) {
    .footer {
      padding: 45px 0 15px;
    }
  }
  .footer p {
    color: #d7d7d7;
  }
  .footer a {
    color: #fff;
    font-weight: 300;
  }
  .footer a:hover {
    opacity: 0.6;
    filter: alpha(opacity=60);
  }
  .footer .read {
    margin: 0;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  .footer .read .fa {
    position: relative;
    top: 1px;
  }
  .footer li {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    font-weight: 300;
  }
  .footer .fa {
    margin-right: 10px;
  }
  .footer .social {
    font-size: 0;
  }
  .footer .social li {
    font-size: 18px;
    display: inline-block;
    margin: 0 7px 0 0 !important;
    list-style: none;
  }
  .footer .social li:last-child {
    margin: 0;
  }
  .footer .social .fa {
    margin: 0;
  }
  
  .footer-bottom {
    width: 100%;
    background-color: #00335A;
    border-top: 1px solid #00243f;
    padding: 30px 0;
    /* mini phones and down */
  }
  @media (max-width: 767px) {
    .footer-bottom {
      padding: 15px 0;
    }
  }
  .footer-bottom div[class*="col-"] {
    /* mini phones and down */
  }
  @media (max-width: 767px) {
    .footer-bottom div[class*="col-"] {
      text-align: center;
      margin-bottom: 15px;
    }
    .footer-bottom div[class*="col-"]:last-child {
      margin: 0;
    }
  }
  .footer-bottom p {
    margin: 0;
    font-size: 12px;
    font-weight: 300;
    color: #fff;
  }
  .footer-bottom a {
    color: #fff;
  }
  .footer-bottom a:hover {
    opacity: 0.6;
    filter: alpha(opacity=60);
  }
  .footer-bottom .navi {
    /* mini phones and down */
  }
  .footer-bottom .navi a {
    color: #fff;
    line-height: 24px;
  }
  .footer-bottom .navi a:hover {
    color: #fff;
    opacity: 0.6;
    filter: alpha(opacity=60);
    background-color: transparent;
  }
  .footer-bottom .navi ul {
    text-align: center;
    line-height: normal;
  }
  .footer-bottom .navi li {
    font-size: 14px;
    line-height: normal;
    margin: 0;
    font-weight: 300;
    text-transform: inherit;
    text-align: center;
  }
  @media (max-width: 767px) {
    .footer-bottom .navi {
      text-align: center !important;
    }
  }
  .footer-bottom .foot-social {
    /* mini phones and down */
  }
  .footer-bottom .foot-social p a {
    color: #828282;
    font-size: 14px;
  }
  .footer-bottom .foot-social p a:hover {
    color: #4cc6f4;
  }
  @media (max-width: 767px) {
    .footer-bottom .foot-social {
      text-align: center;
    }
  }